import React, { Component } from "react"
import styled from "styled-components"

const StyledDonateAnimation = styled.div`
  width: 100%;
  max-width: 20rem;
  margin: 0 auto;

  @media (min-width: ${props => props.theme.bpTablet}) {
    max-width: 30rem;
  }

  @media (min-width: ${props => props.theme.bpDesksm}) {
    max-width: 100%;
  }

  #Donate-product {
    display: none;
  }

  #Donate-money {
    display: none;
  }

  #Donate-volunteer {
    display: none;
  }
`

class DonateAnimation extends Component {
  render() {
    return (
      <StyledDonateAnimation>
        <svg
          id="donate-animation"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 600 600"
        >
          <g id="stand-static" fill="#043044">
            <path
              id="stand11"
              d="M224.9,391.1c0.3,0.8,0.2,1.6-0.1,2.3c48.7,21.8,103.5,21.6,152-0.7 c-0.3-0.7-0.4-1.5-0.1-2.3c0.3-0.8,0.9-1.5,1.7-1.9c7.2-3.4,14.1-7.5,20.8-11.9v-74.9H202.3v75.8c6.7,4.3,13.7,8.3,20.9,11.7 C224.1,389.6,224.7,390.3,224.9,391.1z M249.8,347.4h106.7v18.5H249.8V347.4z"
            />
            <path
              id="stand10"
              d="M385.8,406.3c-1.2,0-2.3-0.6-2.8-1.7l-0.1-0.2c-26,12-54.1,18.4-82.9,18.4 c-28.3,0-55.8-6.1-81.4-17.7l-0.1,0.2c-0.6,1.1-1.7,1.7-2.8,1.7c-0.5,0-0.9-0.1-1.4-0.3c-4.1-1.9-8-4.1-12-6.3v175.9h193.5h3.2 V399.6c-3.9,2.2-7.9,4.4-11.9,6.4C386.8,406.2,386.3,406.3,385.8,406.3z"
            />
            <polygon
              id="stand9"
              points="399.1,295.3 376,269.3 225.5,269.3 202.3,295.3 205,295.3"
            />
            <path
              id="stand8"
              d="M385.8,403.1c10.1-4.9,19.9-10.6,29.2-17.2l-1.9-2.9c1.5-1,2.9-2.2,3.9-3.6 c1.9-2.6,2.9-5.6,3.9-8.4c0.2-0.6,0.4-1.2,0.6-1.7c0.9-2.4,2.1-5.3,4.2-6.7c3.3-2.3,6.8-0.5,8.1,0.3c1.8,1.2,3.5,3.2,4.3,5.5 c-0.6,1.3-0.9,2.7-0.9,4.3v1.1h0.7c-1.2,3-4.1,5.5-7.2,5.7l0.3,6.7c6.6-0.4,12.3-5.7,13.6-12.3h3.3h12.2h22.5v-1.1 c0-4.9-3.2-8.9-7.1-8.9h-3.7c-7.4-7.7-6.4-14.8-5-19.9c2.5,0,4.6-2.6,4.6-5.7v-4.3h-11.3h-11.8v4.3c0,3.2,2,5.7,4.6,5.7 c1.4,5.1,2.4,12.2-5,19.9h-1.2h-2.4c-0.3,0-0.7,0.1-1,0.1c-1.3-2.6-3.4-5-6-6.7c-5-3.2-10.6-3.3-15-0.2c-3.6,2.5-5.5,6.6-6.7,9.9 c-0.2,0.6-0.4,1.2-0.6,1.8c-0.9,2.5-1.7,4.8-3,6.6c-0.6,0.8-1.5,1.6-2.4,2.1l-1.7-2.6c-9,6.4-18.4,11.9-28.1,16.5L385.8,403.1z"
            />
            <path
              id="stand7"
              d="M140.2,331.9c10.4,0,18.8-7.5,18.8-16.7c0-6.7-4.4-12.5-10.8-15.1c-2.4-1-5.1-1.6-8-1.6 c-8.2,0-15.2,4.7-17.8,11.2v0c-0.7,1.7-1.1,3.6-1.1,5.5C121.3,324.4,129.8,331.9,140.2,331.9z"
            />
            <rect id="stand6" x="128" y="379.6" width="24.4" height="189.1" />
            <path
              id="stand5"
              d="M163.9,575.9h-47.4c-10.2,0-19,2.8-22.9,6.7c-1.4,1.4-2.2,2.9-2.2,4.6v1.5h97.5v-1.5 c0-1.6-0.8-3.2-2.2-4.6C182.9,578.7,174.1,575.9,163.9,575.9z"
            />
            <path
              id="stand4"
              d="M152.1,373.8h3.3c1.3,6.6,7.1,12,13.6,12.3l0.3-6.7c-3.1-0.2-6-2.6-7.2-5.7h0.7v-1.1 c0-1.6-0.3-3-0.9-4.3c0.8-2.3,2.4-4.3,4.3-5.5c1.3-0.9,4.8-2.6,8.1-0.3c2.1,1.4,3.4,4.3,4.2,6.7c0.2,0.6,0.4,1.1,0.6,1.7 c1,2.8,2,5.8,3.9,8.4c1,1.4,2.3,2.6,3.9,3.6l-1.9,2.9c9.8,6.9,20.1,12.9,30.7,17.9l6.2-11.7c-10.2-4.8-20.2-10.5-29.7-17.3 l-1.7,2.6c-0.9-0.5-1.7-1.3-2.4-2.1c-1.3-1.8-2.1-4.2-3-6.6c-0.2-0.6-0.4-1.2-0.6-1.8c-1.2-3.3-3.1-7.5-6.7-9.9 c-4.4-3-10.1-3-15,0.2c-2.6,1.7-4.7,4-6,6.7c-0.3-0.1-0.7-0.1-1-0.1h-2.4h-1.3c-7.4-7.7-6.4-14.8-5-19.9c2.5,0,4.6-2.6,4.6-5.7 v-4.3h-11.3h-11.8v4.3c0,3.2,2,5.7,4.6,5.7c1.4,5.1,2.4,12.2-5,19.9h-3.7c-3.9,0-7.1,4-7.1,8.9v1.1h22.9H152.1z"
            />
            <path
              id="stand3"
              d="M459.8,331.9c10.4,0,18.9-7.5,18.9-16.7c0-6.7-4.4-12.5-10.8-15.2c-2.4-1-5.1-1.6-8-1.6 c-8.2,0-15.2,4.7-17.8,11.2v0c-0.7,1.7-1.1,3.6-1.1,5.5C441,324.4,449.4,331.9,459.8,331.9z"
            />
            <rect id="stand2" x="447.6" y="379.6" width="24.4" height="189.1" />
            <path
              id="stand1"
              d="M506.4,582.6c-3.9-4-12.7-6.7-22.9-6.7h-47.4c-10.2,0-19,2.8-22.9,6.7 c-1.4,1.4-2.2,2.9-2.2,4.6v1.5h97.5v-1.5C508.6,585.6,507.8,584,506.4,582.6z"
            />
          </g>
          <g id="Donate-artifacts" fill="#8bc0ba">
            <path
              id="artifacts8"
              d="M271,140.4c-6.8-18.8,7.5-26.9,14.2-16.7c-0.1-2.9-0.6-5.8-1.4-8.7 c-11.6-4.8-29.2,2.3-19.3,31.1C266.2,143.8,268.4,141.9,271,140.4z"
            />
            <path
              id="artifacts7"
              d="M283.7,108.7c21,0,13.2,0,34.1,0c3-1.6,4.6-4.8,4.1-7.9c-26,0-16.3,0-42.4,0 C279.1,103.9,280.7,107.1,283.7,108.7z"
            />
            <path
              id="artifacts6"
              d="M316.4,123.7c6.8-10.4,20.8-1.9,14.1,16.7c2.6,1.6,4.8,3.4,6.6,5.7 c9.4-27.5-6.8-36.3-19.3-31.1C317,117.9,316.5,120.8,316.4,123.7z"
            />
            <path
              id="artifacts5"
              d="M315.6,135.8c-2.4-8.7-2.1-16.8,1.4-25.3h-32.3c3.4,8.5,3.7,16.6,1.3,25.3 L315.6,135.8z"
            />
            <path
              id="artifacts4"
              d="M284.1,223.2h33.3c1.7-3.3,5.2-9.2,8.9-16.7h0c3.9-7.8,8.1-17.4,11-27.5h-73.1 C269.9,198.7,280.6,216.5,284.1,223.2z"
            />
            <path
              id="artifacts3"
              d="M263.1,152.3h75.2c-3.8-8.5-13.4-13.7-22.1-14.2 C292.5,138.1,271.2,134.5,263.1,152.3z"
            />
            <path
              id="artifacts2"
              d="M322.3,171.8v-15.2h17.6c-0.2-0.7-0.4-1.4-0.6-2.1h-77c-0.2,0.7-0.4,1.3-0.6,2.1 h16.7v12.2h-7.1v-2.4h4.6v-7.3h-13.6c0,13.3-0.3,11.5,1.3,17.6H338c0.2-0.8,0.4-1.6,0.6-2.4h-11.4v-12.8h9.3v2.4h-6.8v7.9h9.4 c0.2-1,0.4-2,0.6-3h-6v-2.4h4.6v-7.3h-13.6v15.2h-3.1v-2.4H322.3z M283.1,174.3c-10.9,0-7.5,0-18.4,0v-12.8h9.3v2.4h-6.8v7.9 c5.5,0,8.6,0,13.4,0v-15.2h18.6v12.2h-7.1v-2.4h4.6v-7.3h-13.6V174.3z M303.9,174.3c-10.9,0-7.5,0-18.4,0v-12.8h9.3v2.4H288v7.9 c5.5,0,8.6,0,13.4,0v-15.2H320v12.2h-7.1v-2.4h4.6v-7.3h-13.6V174.3z M321.7,174.3h-15.3v-12.8h9.3v2.4h-6.8v7.9h12.8V174.3z"
            />
            <path
              id="artifacts1"
              d="M315.6,225.6h-14.8h-14.8c-4,0-7.3,3.3-7.3,7.3c16,0,28.2,0,44.3,0 C322.9,228.9,319.6,225.6,315.6,225.6z"
            />
          </g>
          <g id="Donate-product" fill="#8bc0ba">
            <rect
              id="product7"
              x="444.5"
              y="202.5"
              width="12.1"
              height="10.6"
            />
            <rect
              id="product6"
              x="439.9"
              y="185.8"
              width="22.3"
              height="13.5"
            />
            <path
              id="product5"
              d="M466.3,225.9h-0.5l-8.6-9.9h-13.3l-8.6,9.9h-1.5c-3.3,0-6.1,2.7-6.1,6.1h44.6 C472.4,228.6,469.7,225.9,466.3,225.9z"
            />
            <polygon
              id="product4"
              points="465,111.8 479.8,144.4 504.7,133.5 490.3,100.7"
            />
            <path
              id="product3"
              d="M402,137.1l16.4,36.3l16.1-7c1.6,3.1,5.3,4.4,8.5,3l0.2-0.1l3,3.7h-1.7l-3.7,9.7h20.4 l-3.7-9.7h-2.8l-3.3-7.4l8.3-3.6c3.2-1.4,4.7-5,3.6-8.3l13.6-5.9l-15.9-36.5L402,137.1z M465,134.8l-47,20.7l-4.8-9.8l48.1-20.2 L465,134.8z"
            />
            <polygon
              id="product2"
              points="513.7,118.9 507.2,104 499.2,107.5 497.1,102.6 494.1,103.9 504.8,128.6 507.8,127.3 505.7,122.4"
            />
            <path
              id="product1"
              d="M392.5,129.4c-1.3,0.6-1.9,2.2-1.3,3.6l13.8,29.2l4.9-2.3l-13.8-29.2 C395.4,129.4,393.8,128.8,392.5,129.4z"
            />
          </g>
          <g id="Donate-money">
            <path
              id="money1"
              d="M55.8,215.4c-11.4-0.5-22.4-3.1-33-7.7v-26c6.1,3.1,12.6,5.7,19.7,7.7 c7.1,2.1,13.3,3.1,18.7,3.1c4.4,0,7.7-0.5,9.7-1.6c2.1-1.1,3.1-2.9,3.1-5.4c0-1.7-0.7-3.2-2.2-4.4c-1.5-1.2-4-2.6-7.4-4.1 c-3.5-1.5-8.2-3.3-14.1-5.5c-6.6-2.5-12-5.2-16-8.3s-7-6.5-8.9-10.3c-1.9-3.8-2.8-8.5-2.8-14.1c0-8.6,2.8-15.3,8.3-20.2 c5.5-4.9,13.8-7.9,24.9-9V99.1H72v10.6c10.4,0.6,20.9,3.1,31.4,7.7l-9.3,22.3c-10.9-4.9-20.7-7.4-29.5-7.4c-4.2,0-7.1,0.5-8.8,1.5 c-1.7,1-2.5,2.5-2.5,4.5c0,1.7,0.6,3.1,1.8,4.3c1.2,1.1,3.4,2.4,6.4,3.8c3,1.4,7.2,3,12.6,4.8c10.9,3.9,18.7,8.3,23.5,13.2 c4.7,4.9,7.1,11.3,7.1,19.1c0,8.8-2.8,15.9-8.3,21.4c-5.6,5.5-13.7,8.9-24.3,10.2v15.1H55.8V215.4z"
              fill="#8bc0ba"
            />
          </g>
          <g id="Donate-volunteer" fill="#8bc0ba">
            <path
              id="volunteer2"
              d="M215.7,113.4v-12.2c0-1-0.8-1.9-1.9-1.9h-76.6c-1,0-1.9,0.8-1.9,1.9v12.2 c0,1,0.8,1.9,1.9,1.9h3.3c-0.1,2.7-0.1,7.6,1.5,13.7c2.1,8.4,7.7,20.5,21.8,31.2c1.8,1.3,2.8,3.4,2.8,5.6c0,2.2-1,4.3-2.8,5.6 c-14.1,10.6-19.6,22.8-21.8,31.2c-1.4,5.6-1.6,10.2-1.5,13h-3.2c-1,0-1.9,0.8-1.9,1.9v12.2c0,1,0.8,1.9,1.9,1.9h76.6 c1,0,1.9-0.8,1.9-1.9v-12.2c0-1-0.8-1.9-1.9-1.9H211c0.1-2.8-0.1-7.4-1.5-13c-2.1-8.4-7.7-20.5-21.8-31.2c-1.8-1.3-2.8-3.4-2.8-5.6 c0-2.2,1-4.3,2.8-5.6c14.1-10.6,19.6-22.8,21.8-31.2c1.5-6,1.6-10.9,1.5-13.7h2.9C214.9,115.3,215.7,114.5,215.7,113.4z M184.9,156.4c-2.9,2.2-4.6,5.6-4.6,9.3s1.7,7.1,4.6,9.3c10.4,7.8,17.1,17.4,20,28.4c1.4,5.2,1.5,9.6,1.4,12.1h-61.2 c-0.2-6.4,1-25.1,21.4-40.5c2.9-2.2,4.6-5.6,4.6-9.3s-1.7-7.1-4.6-9.3c-10.4-7.8-17.1-17.4-20-28.4c-1.5-5.7-1.5-10.4-1.4-12.7 h61.1C206.7,120.9,206.1,140.5,184.9,156.4z"
            />
            <path
              id="volunteer1"
              d="M175.9,137.9c-9.8,3.6-19.7,0.1-19.7,0.1c2.9,4.8,7.2,9.7,13.4,14.4c0,0,0,0,0,0 c2,1.5,3.4,3.9,4,6.4c0.5,2.1,1,4.5,1.1,6.5c1.2,21,0,24.4-9.4,28.7h0c-8.1,2.9-13.7,9-13.7,16.1h48.4c0-7.1-5.5-13.2-13.6-16.1h0 c-9.5-5-11.5-8.8-10-28.7c0.2-2.1,0.7-4.3,1.3-6.8c0.6-2.4,2-4.6,3.9-6.1c0,0,0.1-0.1,0.1-0.1c6.7-5.1,10.8-9.5,13.7-14.6 C195.7,137.9,187.5,133.7,175.9,137.9z"
            />
          </g>
        </svg>
      </StyledDonateAnimation>
    )
  }
}

export default DonateAnimation
